import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { Icon } from '../icon/icon'
import { SearchBarProps } from './SearchBar'

export type MobileSearchBarProps = SearchBarProps & {
  isOpen?: boolean
}

export const MobileSearchBar: React.FC<MobileSearchBarProps> = ({ onClear, inputRef, focus, isOpen }) => {
  const [inputState, setInput] = useState(false)

  useEffect(() => {
    if (inputRef != null) {
      inputRef.current.focus()
    }
  }, [focus, inputRef])

  const clickHandler = () => {
    setInput(false)

    if (onClear) {
      onClear()
    }
  }

  const onChange = (event) => {
    if (event.target.value === '') {
      setInput(false)
    }
    else {
      setInput(true)
    }
  }

  return (
    <StyledSearchContainer $isOpen={isOpen}>
      <StyledMobileTextField
        inputRef={inputRef}
        $focus={focus}
        onChange={onChange}
        placeholder="Search..."
        inputProps={{ 'aria-label': 'description' }}
      />
      <StyledMobileSearchIcon onClick={clickHandler} icon={inputState ? faTimes : faSearch}/>
    </StyledSearchContainer>

  )
}

const StyledSearchContainer = styled(Grid)<{$isOpen?: boolean}>`
  background-color: rgba(242, 242, 242, 0.8);
  padding: ${props => props.theme.typography.pxToRem(14)} ${props => props.theme.typography.pxToRem(16)} ${props => props.theme.typography.pxToRem(14)} ${props => props.theme.typography.pxToRem(16)};
  position: absolute;
  width: 100%;
  top: ${props => props.theme.typography.pxToRem(75)};
  left: 0;
  opacity: ${props => props.$isOpen ? 1 : 0};
  transform: translateY(${props => props.$isOpen ? '0' : props.theme.typography.pxToRem(-10)});
  transition: transform 0.3s, opacity 0.2s ease-in-out;
`

export const StyledMobileTextField = styled(Input)<{ $focus?: boolean}>`
  background-color: ${props => props.theme.palette.common.white};
  width: 100%;
  border-radius: 4px;
  border: black 1px;
  padding: ${props => props.theme.typography.pxToRem(10)};
  font-size: ${props => props.theme.typography.pxToRem(16)};

  &::before {
    content: none;
  }

  &::after {
    content: none;
  }
`

const StyledMobileSearchIcon = styled(Icon)`
  position: absolute;
  right: 38px;
  color: ${props => props.theme.palette.primary.main};
  font-size: ${props => props.theme.typography.pxToRem(16)};
  top: 50%;
  transform: translate(0, -50%);
`